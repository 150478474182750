import React, { useContext } from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ImpactContext } from '../../components/Layout';
import { Entity } from '@parallelpublicworks/entitysync/';
import { 
    CheckboxField,
    SwitchField
} from '../../components/library/entitysync'; 

const sample_options_short = [
    {
        value: 'am',
        label: 'AM',
    },
    {
        value: 'pm',
        label: 'PM',
    }
]

const sample_options_long = [
    {value: 'val_1', label : 'Pellentesque habitant morbi tristique senectus et netus'},
    {value: 'val_2', label : 'Et malesuada fames ac turpis egestas'},
    {value: 'val_3', label : 'Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante'},
    {value: 'val_4', label : 'Donec eu libero sit'},
    {value: 'val_5', label : 'Amet quam egestas semper'},
    {value: 'val_6', label : 'Aenean ultricies mi vitae est'},
    {value: 'val_7', label : 'Mauris placerat eleifend leo. Quisque sit amet est et sapien ullamcorper pharetra'}
]

function inputSelect() {
    const {user} = useContext(ImpactContext)
    return (
        <LibraryWrapper>
            <Entity componentId="user-entity" type="user--user" source={user}>
                <CheckboxField
                    field="sample_select"
                    label="Sample Select short"
                    onChange={(e) => console.log('WITH CHANGE EVENT')}
                    options={sample_options_short}
                    inline
                    required
                />
                <hr />
                <CheckboxField 
                    field="sample_select_two" 
                    label="Sample Select long" 
                    options={sample_options_long}
                />
                <hr />
                <CheckboxField 
                    field="sample_select_three" 
                    label="Sample Select Error" 
                    error 
                    helperText="Please select an option" 
                    options={sample_options_long}
                />
                <hr />
                <CheckboxField 
                    field="sample_select_bool" 
                    label="Sample Boolean" 
                    boolean
                />
                <hr />
                <SwitchField 
                    field="sample_select_switch" 
                />
            </Entity>
        </LibraryWrapper>
    )
}
export default inputSelect
